import React, {useState, useEffect} from 'react'
import Article from './Article'
import {getCurrentPageData} from '../lib/client'

const ArticlePreview = (props) => {
  const [pageData, setPageData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const data = await getCurrentPageData('page', typeof window !== 'undefined' ? window.location.search : '')
      setPageData(data)
      if (!data || !data.pageData || !data.pageData.items || !data.pageData.items.length) {
        if (typeof window !== 'undefined') {
          let currentLocale = localStorage.getItem('currentLocale') || 'en'
          localStorage.setItem('currentLocale', currentLocale)
          window.location = `/${currentLocale}/${404}`
        }
      }
    }
    fetchData()
  }, [])
  return pageData && pageData.pageData && pageData.pageData.items && pageData.pageData.items.length ? <Article {...props} pages={pageData.pageData.items[0].fields} /> : <></>
}

export default ArticlePreview