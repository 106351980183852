import React, { useContext } from 'react'
import RichTextRenderer from './components/RichTextRenderer'
import { colorPicker, getContrast } from '../lib/utils'
import { sContext } from '../context'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import FloatingMenu from './components/FloatingMenu'
import Ads from './components/Ads'
import FullWidthFade from './components/FullWidthFade'

class Article extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasAds: true
    }

    const {
      sections,
      content
    } = props.pages

    this.menuItemRefs = [React.createRef()]

    if(sections) {
      props.pages.sections.map(section => {
        if(section?.fields) {
          const { fields: {
            menu_title
          }} = section
  
          if(menu_title) {
            this.menuItemRefs.push(React.createRef())
          }
        }
      })

    } else if(content) {
      content.content.map(section => {
        if(section.data.target?.fields?.menuTitle) {
          this.menuItemRefs.push(React.createRef())
        }
      })
    }
  }

  updateListItems() {
    if (typeof window !== 'undefined') {
      const allListItems = document.querySelectorAll('.body-text-content ol > li');
      allListItems.forEach(element => {
        if (!element || !element.textContent || !(`${element.textContent}`.trim())) {
          element.classList.add('hide-list')
        }
      })
    }
  }
  componentDidUpdate() {
    this.updateListItems()
  }
  componentDidMount() {
    this.updateListItems();
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        const sasA = document.querySelectorAll('#sas_84785 a')
        if(sasA.length <= 0 && window.sas !== undefined && window.globalNavPadding) {
          this.setState({ hasAds: false })
        }
      }, 2000)
    }
  }

  //Render
  render() {
    const {
      menuItemRefs,
      state: {
        hasAds
      },
      props: {
        navigation,
        pagePath,
        latestIds,
        footer,
        paths,
        pageId,
        categoryInformation,
        pages,
        pages: {
          content,
          sections,
          navigationColor,
          navigationDefaultColor = 'white-white',
          pageBgColor,
          backgroundColour,
          isBlackTop
        },
        is404,
        correctEntry
      },
      context: {
        location
      }
    } = this

    const isEmbededZoom = (
      location.pathname.includes('dCjRynI8jP2UCs9tXS15u') ||
      location.pathname.includes('webinar-food-canada') ||
      location.pathname.includes('webinaire-gastronomie-canada')
    )

    const isWebinar = (
      isEmbededZoom ||
      location.pathname.includes('webinar-travel-canada') ||
      location.pathname.includes('webinaire-voyage-canada') ||
      location.pathname.includes('677tuKnoououoSiJ82doFg')
    )

    let colorStyles;

    if(pageBgColor) {
      colorStyles = {
        backgroundColor: pageBgColor,
        color: getContrast(pageBgColor)
      }

    } else if(backgroundColour) {
      const colorPickerBgColor = colorPicker(backgroundColour)
      colorStyles = {
        backgroundColor: colorPickerBgColor,
        color: getContrast(colorPickerBgColor)
      }

    } else {
      colorStyles = {
        backgroundColor: 'white',
        color: 'black'
      }
    }

    const backgroundType = colorStyles.color !== 'black' ? 'dark' : 'light'
    let isAeroplanCategory

    if(
      (location.pathname.includes('/en/') && location.pathname.includes('aeroplan-insider')) ||
      (location.pathname.includes('/fr/') && location.pathname.includes('inities-aeroplan'))
    ) {
      if(
        location.pathname.split('aeroplan-insider')[1] === '' ||
        location.pathname.split('aeroplan-insider/')[1] === '' ||
        location.pathname.split('inities-aeroplan')[1] === '' ||
        location.pathname.split('inities-aeroplan/')[1] === ''
      ) {
        isAeroplanCategory = true
      }
    }

    return (
      <div
        id="article"
        className={`
          container-fluid
          ${is404 ? 'is-404' : ''}
          ${location.pathname.includes('/magazine') ? 'is-magazine' : ''}
          ${pageBgColor ? 'has_custom-bg-color' : ''}
          ${backgroundType}-background
          ${!hasAds ? 'no-ads' : ''}
          ${isAeroplanCategory ? 'is-aeroplan' : ''}
          ${isBlackTop ? 'is-black-top' : ''}
        `} >
        <div className="top-ad-wrapper" >
          <Ads
            page='homepage'
            counter={1} />
        </div>
        <div className="main-wrapper">

          <Navigation
            navigationColor={navigationColor}
            default={navigationDefaultColor}
            paths={paths || {}}
            menu={navigation}
            forceShowCategories={
              (pagePath === 'magazine' || location.pathname.includes('magazine/')) || isAeroplanCategory
            }
            {...colorStyles}
          />
          <main>
            <RichTextRenderer
              content={content}
              pages={pages}
              sections={sections}
              refs={menuItemRefs}
              isEmbededZoom={isEmbededZoom}
              ignoreAds={!!this.props.ignoreAds}
              latestIds={latestIds}
              pageId={pageId}
              moduleOverrides={this.props.moduleOverrides || {}}
              categoryInformation={categoryInformation}
              correctEntry404={correctEntry}
              isAeroplanCategory={isAeroplanCategory} />

            {/* // FRONTEND TEMPORARY */}
            {isWebinar && (
              <FullWidthFade
                hardColor={true}
                noTopPadding
                pageBg='#FAFAFA' >
                <div className="powered-by">
                  <p>
                    {
                      location.pathname.split('/')[1] ==='en' ?
                      'Powered By' : 'Contenu créé par'
                    }
                  </p>
                  <div />
                </div>
              </FullWidthFade>
            )}
            <Footer
              footer={footer}
              location={location} />

            {false && (menuItemRefs && menuItemRefs[0].current) && <FloatingMenu refs={menuItemRefs} />}
          </main>
        </div>
      </div>
    )
  }
}

Article.contextType = sContext

export default Article
