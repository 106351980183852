import React, { useContext } from 'react';
import { useEffect } from 'react';
import Layout, { buildProps } from './index.js';
import Article from './main-components/Article';
import ArticlePreview from './main-components/ArticlePreview';
import { getEntries } from './lib/client'
import { sContext } from './context.js';
import { useState } from 'react';

const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    paths,
    latestIds
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths,
    is404: props.is404,
    correctEntry: props.correctEntry
  }
  
  if (props.is404) {
    return <Article
      {...addedProps}
      ignoreAds />

  } else {
    return <ArticlePreview
      {...addedProps}
      latestIds={latestIds} />
  }
}

const Page = (props) => {
  const {
    location,
    pageContext: pPageContext,
  } = props;

  const [correctEntry, setCorrectEntry] = useState('');
  const contentfulEnv = useContext(sContext).contentfulEnv;

  let lang = location.pathname.includes('/fr/') ? 'fr' : 'en';
  const pageContext = pPageContext[lang].context;

  const context = {
    ...props,
    pageContext
  }

  const getSlug = () => {
    for (let i = 0; i < pageContext.allCategoryPaths.length; i++) {
      const categoryPath = pageContext.allCategoryPaths[i];
      const splitPath = location.pathname.split(`${categoryPath}/`);

      if(splitPath.length > 1) {
        return splitPath[1].split('/').join('');
      }
    }
  }

  const getEntry = async (slug) => {
    const rawEntry = await getEntries({
      'content_type': 'page',
      'fields.path[in]': slug,
      include: 3,
      'fields.hidePage[neq]': true,
      'fields.temporarilyHideHotel[neq]': true
    }, lang, contentfulEnv)

    const {
      items: [ entry ]
    } = rawEntry;

    if(!entry?.fields.category) return

    setCorrectEntry(entry);
 }
  
  useEffect(() => {
    const slug = getSlug();
    if(!slug) return

    getEntry(slug);
  }, [props]);

  return (
    <Layout {...context}>
      <Component
        {...context}
        is404={typeof window !== 'undefined' && window.location.search.indexOf('preview=true') === -1}
        correctEntry={correctEntry} />
    </Layout>
  )
}

export default Page