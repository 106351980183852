import React from 'react'
class FloatingMenu extends React.Component {
  state = {
    currentIndex: -1
  }
  parentRef = React.createRef()
  constructor(props) {
    super(props)

    this.items = props.refs.map(({ current: ref }, i) => ({
      ref,
      title: ref.dataset.menutitle,
      index: i === props.refs.length - 1 ? -1 : i
    }))

    this.handleScroll = this.handleScroll.bind(this)
  }
  
  handleScroll(e) {
    const foundItems = this.items.filter(item => {
      if(typeof window === 'undefined') { false }
      return item.ref.getBoundingClientRect().top < window.innerHeight / 2
    })

    const foundItem = foundItems.pop()
    this.setState({
      currentIndex: foundItem ? foundItem.index : -1
    })
    if (foundItem && foundItem.index >= 0 && this.parentRef) {
      const child = this.parentRef.current.children[foundItem.index]
      child.scrollIntoView()
    }
  }

  componentDidMount() {
    if(typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleClick(index) {
    if(typeof window !== 'undefined') {
      window.scrollTo(0, this.items[index].ref.offsetTop)
    }
  }

  render() {
    if (!this.items || !this.items.length) {
      return null
    }

    return (
      <div className="cFloatingMenu" data-index={this.state.currentIndex}>
        <div className="cFloatingMenu-inner" ref={this.parentRef}>
          {this.items.map((itm, idx) => {
            if(idx === this.items.length - 1) { return }

            return (
              <button
                key={`floating-${idx}`}
                className={`
                  cFloatingMenu-btn
                  ${idx === this.state.currentIndex ? 'active': ''}
                `}
                onClick={this.handleClick.bind(this, idx)} >
                {itm.title}
              </button>
            )
          })}
        </div>
      </div>
    )
  }
}

export default FloatingMenu
